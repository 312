import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, Injector, Injectable } from '@angular/core';
import { EventManagerService } from '../service/event-manager.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '../service/dialog.service';
import { InputDataPopupComponent } from './input-data-popup.component';

@Injectable({ providedIn: 'any' })
export abstract class PopupHasCheckChangeComponent extends InputDataPopupComponent implements OnInit, OnDestroy {
  isChanged: boolean = false;
  constructor(
    public eventManager: EventManagerService,
    public activeModal: NgbActiveModal,
    public dialogService: DialogService,
  ) {
    super(eventManager, activeModal);
  }
  checkDismiss(message?, header? ) {
    this.checkChange();
    if (!this.isChanged) {
      this.dismiss();
    } else {
      this.showWarning(message, header);
    }
  }
  showWarning(message, header) {
    super.showWarning(message, header);
    setTimeout(() => {
      const backdrop = document.getElementsByTagName('ngb-modal-backdrop')[1];
      if (backdrop) {
          backdrop.classList.add("large-z-index-backdrop");
      }
    }, 200)
  }
}
