<div class="block" [formGroup]="editForm">
    <div class="block__input">
        <div class="form-group">
            <label for="">{{titleStart}} <span class="required">*</span></label>
            <div class="form-group-input">
                <select class="form-control disabled" formControlName="startOperator">
                    <option [value]="operator.code" *ngFor="let operator of startOperators">{{operator.name}}</option>
                </select>

                <input-number [maxLength]="13" [disabled]="true" [maxLengthDecimal]="1"
                    [amount]="editForm.value.startPoint" (amountChange)="patchValueNumber('startPoint', $event)"
                    *ngIf="!isInputYear">
                </input-number>
                <input-number [maxLength]="4" [disabled]="true" [amount]="editForm.value.startPoint"
                    [isSeparate]="false" (amountChange)="patchValueNumber('startPoint', $event)" *ngIf="isInputYear">
                </input-number>
            </div>

        </div>

        <div class="form-group" *ngIf="!isLasted">
            <label for="">{{titleEnd}}  <span class="required">*</span></label>
            <div class="form-group-input">
                <select class="form-control" formControlName="endOperator" [ngClass]="isLasted ? 'disabled' : ''">
                    <option [value]="operator.code" *ngFor="let operator of endOperators">{{operator.name}}</option>
                </select>
                <input-number [maxLength]="13" [maxLengthDecimal]="1" [amount]="editForm.value.endPoint"
                    (amountChange)="patchValueNumber('endPoint', $event)" *ngIf="!isInputYear">
                </input-number>
                <input-number [maxLength]="4" [isSeparate]="false" [amount]="editForm.value.endPoint"
                    (amountChange)="patchValueNumber('endPoint', $event)" *ngIf="isInputYear">
                </input-number>
            </div>
        </div>

        <div class="form-group">
            <label for="" style="min-width: 200px;">{{titleValue}} <span class="required">*</span></label>
            <div class="">
                <input-number [maxLength]="13" [maxLengthDecimal]="1" [amount]="editForm.value.value"
                    (amountChange)="patchValueNumber('value', $event)" *ngIf="!isInputYear">
                </input-number>
                <input-number [maxLength]="3" [isDecimal]="true" [maxLengthDecimal]="2" [isInteger]="false"  [amount]="editForm.value.value"
                    [unit]="'%'" (amountChange)="patchValue('value', $event)" *ngIf="isInputYear"></input-number>
            </div>
        </div>

        <span class="btn__remove" title="Xóa" (click)="deletePoint()" *ngIf="!isFirst &&  !isLasted">Xóa</span>
    </div>
    <div class="block__input" style="align-items: flex-start;">

        <div class="w-100 d-flex flex-column">
            <app-error-text [isDisplay]="isSaved && editForm.get('endPoint')?.errors?.required"
                [message]="('Giá trị kết thúc là bắt buộc')">
            </app-error-text>
            <app-error-text [isDisplay]="isSaved && editForm.get('endOperator')?.errors?.required"
                [message]="('Toán tử là bắt buộc' )">
            </app-error-text>
            <app-error-text [isDisplay]="isSaved && editForm.get('value')?.errors?.required"
                [message]="('% tăng trưởng bình quân là bắt buộc' )">
            </app-error-text>
            <app-error-text [isDisplay]="isSaved && errors.startPoint"
                [message]="('Giá trị kết thúc phải lớn hơn giá trị bắt đầu' )">
            </app-error-text>
        </div>
    </div>
    <div class="block__input" style="align-items: flex-start;" *ngIf="isSaved && errors.endPoint">
        <app-error-text [isDisplay]="isSaved && errors.endPoint" [message]="('Giá trị kết thúc phải lớn hơn 1' )">
        </app-error-text>
    </div>
</div>