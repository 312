<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="evaluateLabel">
      Thiết lập trọng số các mục đánh giá
    </h5>
    <button (click)="checkDismiss()" type="button" class="close" aria-label="Close">
      <img src="./assets/images/icons/ic-close.svg" />
    </button>
  </div>
  <div class="modal-body">
    <p>
      Việc xác định trọng số cho từng mục thông tin sẽ dựa trên tầm quan trọng
      của nó đối với SKTC của khách hàng.
    </p>

    <div class="notice-limited-functionality" *ngIf="isEdit">
      <p>Trọng số mới sẽ được áp dụng cho điểm đánh giá mới, những điểm đã đánh giá sẽ không bị thay đổi.</p>
    </div>

    <div class="row">
      <div class="col-10">
        <div class="border-table common__table">
          <table class="table">
            <thead>
              <tr style="height: 45px">
                <th>Mục đánh giá</th>
                <th class="text-left">Trọng số</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of  configItems">
                <td>{{ item.name }}</td>
                <td class="text-left">
                  <div class="input-group" *ngIf="isEdit">
                    <input-number [maxLength]="2" [amount]="item.value" [unit]="'%'"
                      (result)="changeValue(item, $event)"></input-number>
                  </div>
                  <div *ngIf="!isEdit">
                    {{ !item.value ? item.recommendPoint : item.value }}%
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td><strong>Tổng</strong></td>
                <td class="text-left">
                  <strong>{{ total === 0 ? 100 : total }}%</strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="group__btn">
          <em [ngClass]="isSaved && errors?.percent ? 'required' : ''">Tổng trọng số phải bằng 100%</em>
          <em class="required" *ngIf="isSaved && errors?.isNull">Thông tin này không được bỏ trống</em>
          <div class="group__btn--element">
            <button class="btn common__btn common__btn--secondary" (click)="isEdit = true" *ngIf="!isEdit">
              Chỉnh sửa
            </button>
            <button class="btn common__btn" (click)="save()" *ngIf="isEdit">
              Lưu
            </button>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="border-table common__table">
          <table class="table">
            <thead>
              <tr>
                <th>Tham khảo</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of configItems">
                <td class="disabled">
                  {{ item.recommendPoint }}%
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="line-height-edit">
                <td class="text-left">
                  <strong>100%</strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- <div class="col-12">
        <div class="reference__rules" *ngIf="textRules">
          <span class="reference__rules--title">
            <img src="./assets/images/icons/ic-idea.svg" alt="" />
            Quy tắc tham khảo
          </span>

          <div class="reference__rules--list" [innerHTML]="textRules"></div>
        </div>
      </div> -->
    </div>
  </div>
</div>