<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="evaluateLabel">
            Thiết lập tỷ lệ tăng trưởng hàng năm dự kiến
        </h5>
        <button (click)="dismiss()" type="button" class="close" aria-label="Close">
            <img src="./assets/images/icons/ic-close.svg" />
        </button>
    </div>

    <!-- Modal body -->
    <div class="row">
        <div class="col-12">
            <ul class="common__tab nav nav-tabs">
                <li class="nav-item" (click)="chooseTab(TABS.ASSET)">
                    <a class="nav-link" [ngClass]="tab === TABS.ASSET ? 'active' : ''">Sản phẩm đầu tư</a>
                </li>
                <li class="nav-item" (click)="chooseTab(TABS.INFLATIONAR)">
                    <a class="nav-link" [ngClass]="tab === TABS.INFLATIONAR ? 'active' : ''">Lạm phát</a>
                </li>
                <li class="nav-item" (click)="chooseTab(TABS.INCOME)">
                    <a class="nav-link" [ngClass]="tab === TABS.INCOME ? 'active' : ''">Thu nhập</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="modal-body">
        <div class="form__survey">
            <div class="row">

                <div class="col-12" *ngIf="dataConfig">
                    <div *ngIf="tab === TABS.ASSET">
                        <tab-config-asset-annual-growth [config]="dataConfig['ASSET']"
                            [assetsNeedInput]="assetsNeedInput"></tab-config-asset-annual-growth>
                    </div>
                    <div *ngIf="tab === TABS.INFLATIONAR">
                        <tab-config-inflationary-annual-growth
                            [config]="dataConfig['INFLATION']"></tab-config-inflationary-annual-growth>
                    </div>
                    <div *ngIf="tab === TABS.INCOME">
                        <tab-config-income-annual-growth
                            [configActive]="dataConfig['ACTIVE_INCOME']"
                            [configPassive]="dataConfig['PASSIVE_INCOME']">
                        </tab-config-income-annual-growth>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal footer -->
</div>