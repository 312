import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ComprehensivePlanService {

  constructor(
    private http: HttpClient,
  ) {}

  getPlanList(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/list', body);
  }
  getDetailPlan(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/detail/' + body.id, body);
  }
  getDetailNormalPlan(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-health-check/detail/' + body.id, body);
  }
  createComprehensivePlan(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/create-new', body);
  }
  createComprehensivePlanStep1(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/create/step-1', body);
  }
  createComprehensivePlanStep2(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/create/step-2', body);
  }
  createComprehensivePlanStep3(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/create/step-3', body);
  }
  createComprehensivePlanStep4(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/create/step-4', body);
  }
  createComprehensivePlanStep5(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/create/step-5', body);
  }
  createComprehensivePlanStep6(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/create/step-6', body);
  }

  buildingComprehensivePlanStep1(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/building/step-1', body);
  }
  buildingComprehensivePlanStep2(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/building/step-2', body);
  }
  buildingComprehensivePlanStep3(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/building/step-3', body);
  }
  buildingComprehensivePlanStep4(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/building/step-4', body);
  }
  buildingComprehensivePlanStep5(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/building/step-5', body);
  }
  buildingComprehensivePlanStep6(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/building/step-6', body);
  }
  updateInfoCustomer(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning-update-info', body);
  }
  updateStatusPlan(body) {
    return  this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning-status-update', body);
  }
  getConfigAnnualGrowth() {
    return this.http.get(environment.urlBackEnd + 'agency-partner/financial-planning/grow-config');
  }
  updateConfigAnnualGrowth(body) {
    return this.http.put(environment.urlBackEnd + 'agency-partner/financial-planning/grow-config-update', body);
  }
  updateConfigAnnualGrowthForProfilePlan(body) {
    return this.http.put(environment.urlBackEnd + 'agency-partner/financial-planning/grow-config-profile-update', body);
  }
  startIncomeGrowthSimulatorRun(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/income-grow-simulator-run', body);
  }
  startCostGrowthSimulatorRun(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/cost-grow-simulator-run', body);
  }
  startAssetGrowthSimulatorRun(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/asset-grow-simulator-run', body);
  }
  toolCalculateLifeInsurance(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/life-insurance-calculation', body);
  }
  toolCalculateHealthInsurance(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/non-life-insurance-calculation', body);
  }
  toolCalculatePeacefulRetirement(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/peaceful-retirement-calculation', body);
  }
  toolCalculateFinancialFreedom(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/financial-freedom-calculation', body);
  }
  toolCalculateGoalCalculation(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-planning/goal-calculation', body);
  }
  saveFinal(body) {
    return this.http.post(environment.urlBackEnd + `agency-partner/financial-planning/${body.id}/history/save`, body);
  }
  exportFile(body) {
    return this.http.post( environment.urlBackEnd +  'agency-partner/financial-planning/export/' + body.id, body, { responseType:'blob', observe: 'response' });
  }
  exportFileError(body) {
    return this.http.post( environment.urlBackEnd +  'agency-partner/financial-planning/export/' + body.id, body, {  observe: 'response' });
  }
  disablePoint(body) {
    return this.http.post(environment.urlBackEnd + `agency-partner/financial-planning/${body.id}/history/disable`, body);
  }
  reviewMail(body) {
    return this.http.get( environment.urlBackEnd +  'agency-partner/financial-planning/preview-send-customer/' + body.id);
  }
  sendMail(body) {
    return this.http.post( environment.urlBackEnd +  'agency-partner/financial-planning/send-customer/' + body.id, body, { observe: 'response' });
  }
  historySendMail(body) {
    return this.http.post( environment.urlBackEnd +  'agency-partner/financial-planning/send-history-list/' + body.id, body);
  }
  remindCustomerCare(body) {
    return this.http.post( environment.urlBackEnd +  'agency-partner/financial-health-check/remind/update', body);
  }
  getAverageInflation(body) {
    return this.http.post( environment.urlBackEnd +  'agency-partner/financial-planning/average-inflation', body);
  }
}
