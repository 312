<div class="row">
    <div class="col-md-4 mb-3">
        <ul class="nav nav-pills flex-column" id="income" role="tablist">
            <li class="nav-item">
                <a class="nav-link" id="active-tab" [ngClass]="config?.type === 'ACTIVE' ? 'active' : ''"
                 (click)="checkChangeIncome(TABS.ACTIVE)">
                    <div class="common__block--result justify-content-center">
                        <div class="element ">
                            <h5>Thu nhập chủ động</h5>
                            <span class="update__date"><em>Ngày cập nhật:</em>
                                {{configActive?.updateTime ? (configActive?.updateTime | date: 'dd/MM/yyyy') : '-/-'}}
                            </span>
                        </div>
                    </div>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="passive-tab" 
                [ngClass]="config?.type === 'PASSIVE' ? 'active' : ''" (click)="checkChangeIncome(TABS.PASSIVE)">
                    <div class="common__block--result justify-content-center green">
                        <div class="element ">
                            <h5>Thu nhập thụ động</h5>
                            <span class="update__date"><em>Ngày cập nhật:</em>
                                {{configPassive?.updateTime ? (configPassive?.updateTime | date: 'dd/MM/yyyy') : '-/-'}}
                            </span>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
    <!-- /.col-md-4 -->
    <div class="col-md-8">

        <input-value-start-end [title]="config?.title" [isCustomizable]="config?.isCustomizable"
            [updateTime]="config?.updateTime" [list]="config?.list"
            [typeId]="config?.typeId" [isTooltip]="config?.isTooltip"
            [tooltipContent]="config.tooltipContent">
        </input-value-start-end>
        
        <!-- <div class="tab-content" id="incomeContent">
            <div class="tab-pane fade show active" id="active" role="tabpanel" aria-labelledby="active-tab">
                <div class="point__setup">
                    <div class="point__setup--content" *ngIf="tab === TABS.ACTIVE">
                        <input-value-start-end [title]="'Thu nhập chủ động'" [isCustomizable]="false"
                            [updateTime]="configActive?.updateTime" [list]="configActive?.list"
                            [typeId]="configActive?.typeId" [isTooltip]="'true'"
                            [tooltipContent]="'Thu nhập chủ động bao gồm thu nhập từ Lương và nguồn thu nhập chủ động khác.'">
                        </input-value-start-end>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="passive" role="tabpanel" aria-labelledby="passive-tab">
                <div class="point__setup">
                    <div class="point__setup--content" *ngIf="tab === TABS.PASSIVE">
                        <input-value-start-end [title]="'Thu nhập thụ động'" [isCustomizable]="false"
                            [updateTime]="configPassive?.updateTime" [list]="configPassive?.list"
                            [typeId]="configPassive?.typeId" [isTooltip]="'true'"
                            [tooltipContent]="'Thu nhập thụ động bao gồm thu nhập từ Lãi đầu tư, Tiền bản quyền và nguồn thu nhập thụ động khác.'">
                        </input-value-start-end>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <!-- /.col-md-8 -->
</div>