import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class FinancialNormalPlanService {

  constructor(
    private http: HttpClient,
  ) {}

  getPlanList(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-health-check/list', body);
  }
  deletePlan(id) {
    return this.http.delete(environment.urlBackEnd + 'agency-partner/financial-health-check/detail/' + id);
  }
  getDetailPlan(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-health-check/detail/' + body.id, body);
  }
  getDetailNormalPlan(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-health-check/detail/' + body.id, body);
  }
  createNormalPlan(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-health-check-create-new', body);
  }
  createNormalPlanStep1(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-health-check-create-step-1', body);
  }
  createNormalPlanStep2(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-health-check-create-step-2', body);
  }
  createNormalPlanStep3(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-health-check-create-step-3', body);
  }
  createNormalPlanStep4(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-health-check-create-step-4', body);
  }
  createNormalPlanStep5(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-health-check-create-step-5', body);
  }
  createNormalPlanStep6(body) {
    return this.http.post(environment.urlBackEnd + 'agency-partner/financial-health-check-create-step-6', body);
  }
  calculatetionPoint(body) {
    return  this.http.post(environment.urlBackEnd +  'agency-partner/financial-health-check/result/' + body.id, body);
  }
  updateStatusPlan(body) {
    return  this.http.post(environment.urlBackEnd +  'agency-partner/financial-health-check-status-update', body);
  }
  exportFile(body) {
    return this.http.post( environment.urlBackEnd +  'agency-partner/financial-health-check/export/' + body.id, body, { responseType:'blob', observe: 'response' });
  }
  exportFileError(body) {
    return this.http.post( environment.urlBackEnd +  'agency-partner/financial-health-check/export/' + body.id, body, {  observe: 'response' });
  }
  reviewMail(body) {
    return this.http.get( environment.urlBackEnd +  'agency-partner/financial-health-check/preview-send-customer/' + body.id);
  }
  sendMail(body) {
    return this.http.post( environment.urlBackEnd +  'agency-partner/financial-health-check/send-customer/' + body.id, body, { observe: 'response' });
  }
  historySendMail(body) {
    return this.http.post( environment.urlBackEnd +  'agency-partner/financial-health-check/send-history-list/' + body.id, body);
  }
  getConfigPoint() {
    return  this.http.get(environment.urlBackEnd +  'agency-partner/financial-health-check/evaluation-config/detail');
  }
  updateConfigPoint(body) {
    return this.http.post( environment.urlBackEnd +  'agency-partner/financial-health-check/evaluation-config/update', body);
  }
  public getProducts(body) {
    return  this.http.post(environment.API_FMARKET + '/res/products/filter', body);
  }
  remindCustomerCare(body) {
    return this.http.post( environment.urlBackEnd +  'agency-partner/financial-health-check/remind/update', body);
  }
}
