import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionStorageService } from "ngx-webstorage";
import { Subscription } from "rxjs";
import { EventManagerService } from "src/app/common/service/event-manager.service";
import { Constant } from "src/app/common/constant";
import { DialogService } from "src/app/common/service/dialog.service";
import { AppQueries } from "../../../../../state";
import { FormEditCheckChangeComponent } from "../../../../../common/extend-code/form.edit.check.change.component";
import { InputValueStartEndComponent } from "../../../share-plan/components/input-value-start-end/input-value-start-end.component";

@Component({
  selector: "tab-config-inflationary-annual-growth",
  styleUrls: ["./tab-config-inflationary-annual-growth.component.scss"],
  templateUrl: "./tab-config-inflationary-annual-growth.component.html",
})
export class TabConfigInflationarysAnnualGrowthComponent extends FormEditCheckChangeComponent implements OnInit, OnChanges
{ 
  @ViewChild(InputValueStartEndComponent) inputValueStartEndComponent: InputValueStartEndComponent; 
  public Constant = Constant;
  subscriptions?: Subscription[] = [];
  @Input() config: any;
  list: any[] = [];
  typeId: any;
  constructor(
    public eventManager: EventManagerService,
    public dialogService: DialogService,
    public route: ActivatedRoute,
    public cdf: ChangeDetectorRef,
    private appQueries: AppQueries,
    public sessionStorageService: SessionStorageService,
  ) {
    super(eventManager)
  }

  ngOnInit() {
    this.subscriptions = [
      this.eventManager.subscribe('get-current-tab', (res) => {
        this.eventManager.broadcast({
          name: 'emit-current-tab',
          content: this
        })
      }),
    ];
    this.eventManager.broadcast({
      name: 'emit-current-tab',
      content: this
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      setTimeout(() => {
        if (!this.config) {
          this.config = {};
          this.list = [];
        } else {
          this.list = this.config.list || [];
          this.typeId = this.config.typeId;
        }
      }, 200)
    }
  }
  checkChangeRouter() {
    return !this.inputValueStartEndComponent.isChanged;
  }
}