<div class="row">
  <div class="col-4">
    <div class="border-table common__table">
      <table class="table">
        <thead>
          <tr style="height: 45px">
            <th>Loại tài sản</th>
            <th class="text-left">Loại sản phẩm</th>
          </tr>
        </thead>
        <tbody *ngFor="let item of assets">
          <tr>
            <td [attr.rowspan]="item.child.length + 1">{{item.name}}</td>
          </tr>
          <tr *ngFor="let child of item.child">
            <td (click)="checkChangeConfig(child)" [ngClass]="selectedAsset?.id === child.id ? 'active' : ''"
              [style.backgroundColor]="child.backgroundColor">
              <span>{{child.name}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-8 common__sticky">
    <input-value-start-end [title]="selectedAsset?.name" [updateTime]="configChild?.updateTime"
      [list]="configChild?.list" [typeId]="configChild?.typeId" [assetId]="selectedAsset?.id">
    </input-value-start-end>
  </div>
</div>