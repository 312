import { CopyProperties } from "src/app/common/decorator/copy-properties.decorator";

@CopyProperties()
export class ConfigPoint  {
  public titleStart: string;
  public titleEnd: string;
  public titleValue: string;
  public isSeparate: boolean;
  constructor(params?: any) {
    this.titleStart = '';
    this.titleEnd = '';
    this.titleValue = '';
    this.isSeparate = true;
  }
}
