export class ObjectUtil {
  static parseFullProperty(obj, rootObj) {
    Object.keys(rootObj).forEach((property) => {
      obj[property] = rootObj[property];
    })
    return obj;
  }
  static parseList(obj) {
    const list = [];
    Object.keys(obj).forEach((property) => {
      list.push(obj[property]);
    })
    return list;
  }
  static listToObject(list: any[]) {
    const obj: any = {};
    (list || []).forEach((item: any, index) => {
      obj[index] = item;
    });
    return obj;
  }
  static listToObjByField(list: any[], field: string = 'id') {
    const obj: any = {};
    (list || []).forEach((item: any) => {
      obj[item[field]] = item;
    });
    return obj;
  }
  static objectToList(obj: any) {
    let list = [];
    Object.keys(obj).forEach((key) => {
      list.push(obj[key]);
    })
    return list;
  }
}
