import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";
import { EventManagerService } from "src/app/common/service/event-manager.service";
import { Constant } from "src/app/common/constant";
import { DialogService } from "src/app/common/service/dialog.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ComprehensivePlanService } from "../comprehensive-plan.service";
import { AppQueries } from "../../../../state";
import { TabCommonCheckChangeComponent } from "../create-comprehensive-plan/tab-common-check-change.component";
import { ActivatedRoute, Router } from "@angular/router";

const TABS = {
  ASSET: "ASSET",
  INFLATIONAR: 'INFLATIONAR',
  INCOME: "INCOME",
};


@Component({
  selector: "config-annual-growth-popup",
  styleUrls: ["./config-annual-growth-popup.component.scss"],
  templateUrl: "./config-annual-growth-popup.component.html",
})
export class ConfigAnnualGrowthPopupComponent extends TabCommonCheckChangeComponent  implements OnInit, OnDestroy
{
  public Constant = Constant;
  subscriptions: Subscription[] = [];
  TABS = TABS;
  @Input() tab: string;
  @Input() assetsNeedInput: any;
  dataConfig: any = {};
  growSettingTypes: any[] = [];
  constructor(
    public eventManager: EventManagerService,
    public activeModal: NgbActiveModal,
    public dialogService: DialogService,
    private appQueries: AppQueries,
    public route: ActivatedRoute,
    public router: Router,
  ) {
    super(eventManager, route, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscriptions = [
      this.appQueries.configComprehensive$.subscribe((configComprehensive) => {
        if (!configComprehensive) {
          return;
        }
        this.dataConfig = JSON.parse( JSON.stringify(configComprehensive.configGrowthAnnualYear)) ;
      }),
    ];
    this.chooseTab(this.tab || TABS.ASSET);
  }
  ngOnDestroy(): void {
  }
  dismiss() {
    this.activeModal.dismiss();
  }
  save() {
    this.dismiss();
  }
  moveRouter() {}
  showWarning(message, title) {
    super.showWarning(message, title);
    setTimeout(() => {
      const backdrop = document.getElementsByTagName('ngb-modal-backdrop')[1];
      if (backdrop) {
          backdrop.classList.add("large-z-index-backdrop");
      }
    }, 200)

  }
}

