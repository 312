import {
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { EventManagerService } from "src/app/common/service/event-manager.service";
import { Constant } from "src/app/common/constant";


@Injectable({ providedIn: 'any' })
export class TabCommonCheckChangeComponent implements OnInit, OnDestroy, OnChanges
{
  @Input() profile;
  public Constant = Constant;
  subscriptions?: Subscription[] = [];
  TABS: any;
  activeTabs: any = {};
  tab: string;
  profileId: any = '';
  completeTab: number = 0;
  selectedComponent: any;
  nextTab: string;
  constructor(
    public eventManager: EventManagerService,
    public route: ActivatedRoute,
    public router: Router,
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.eventManager.subscribe("emit-current-tab", (res) => {
        this.selectedComponent = res.content;
      }),
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.profile && changes.profile.currentValue) {
      if (this.profile) {
        this.checkStatusTab();
      }
    }
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.eventManager.destroys(this.subscriptions);
    }
  }
  checkStatusTab() {}
  chooseTab(tab) {
    this.nextTab = tab;
    this.checkTab();
  }
  checkTab() {
    if (!this.selectedComponent) {
      this.changeTab(this.nextTab);
      return;
    }
    this.selectedComponent.confirmChangeRouter()
    .then((res) => {
      if (res) {
        this.changeTab(this.nextTab);
      } else {
        this.showWarning();
      }
    })
  }
  changeTab(tab) {
    this.tab = tab;
    this.moveRouter();
    setTimeout(() => {
      this.eventManager.broadcast({
        name: 'get-current-tab'
      });
    }, 500)
  }
  moveRouter() {
    // setTimeout(() => {
      const queryParams = this.route.snapshot.queryParams;
      this.router.navigate(
        [ Constant.url_financial_comprehensive_plan_create + (this.profile ? "/" + this.profile.id : "")],
        { queryParams: { step: queryParams.step, tab: this.tab } }
      );
    // }, 200)
  }
  showWarning(message?, title?) {
    message = message || 'Thông tin của bạn chưa được lưu, bạn có muốn thoát?';
    title = title || 'Chưa lưu';
    const properties = {
      message,
      title,
      type: 'confirm',
      isNoDismissAll: true,
      cancelFunc: this.cancelFuncWarning.bind(this),
      func: this.funcWarning.bind(this),
    };
    this.eventManager.broadcast({
      name: 'show-dialog',
      content: properties
    })
  }
  cancelFuncWarning() {}
  funcWarning() {
    this.changeTab(this.nextTab);
  }
}
