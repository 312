import { CopyProperties } from "src/app/common/decorator/copy-properties.decorator";

@CopyProperties()
export class NormalPoint  {
  public name?: string;
  public startPoint?: number;
  public endPoint?: number;
  public startOperator?: string;
  public startOperatorId?: number;
  public endOperator?: string;
  public endOperatorId?: number;
  public value?: any;
  public isError?: boolean;
  constructor(params?: any) {
    this.name = '';
    this.startPoint = null;
    this.endPoint = null;
    this.startOperator = '';
    this.startOperatorId = null;
    this.endOperator = '';
    this.endOperatorId = null;
    this.value = null;
    this.isError = false;
  }
}
