import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, Injector, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { EventManagerService } from '../service/event-manager.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ObjectUtil } from '../utils/object.util';
import { Constant } from '../constant';
import { FormEditCheckChangeComponent } from './form.edit.check.change.component';

@Injectable({ providedIn: 'any' })
export abstract class InputDataPopupComponent extends FormEditCheckChangeComponent implements OnInit, OnDestroy {
  @Input() dataForm: any = {};
  id: any;
  editForm: any;
  detailObject: any = {};
  errors: any = {};
  isSaved: boolean = false;
  isUpload: boolean = false;
  subscriptions?: Subscription[] = [];
  eventBoardCast: string = '';
  public Constant = Constant;
  constructor(
    public eventManager: EventManagerService,
    public activeModal: NgbActiveModal,
  ) {
    super(eventManager);
  }
  ngOnInit() {
    super.ngOnInit();
    this.eventBoardCast  = this.dataForm.eventBoardCast || this.eventBoardCast;
    this.updateForm();
  }
  updateForm() {
    this.editForm && this.editForm.patchValue(ObjectUtil.parseFullProperty({}, this.dataForm));
  }
  dismiss() {
    this.activeModal.dismiss();
  }
  save() {
    this.isSaved = true;
    const result = this.disabledButton();
    if (result || this.editForm.invalid) {
        return;
    }
    this.eventManager.broadcast({
        name: this.eventBoardCast,
        content: ObjectUtil.parseFullProperty({}, this.editForm.value)
    });
    this.dismiss();
  }
}
